import React, {FC, ReactNode} from "react";
import classNames from "classnames";
import Link from "next/link";
import dynamic from 'next/dynamic'
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import {Account, History, Location, LogOut} from "../../svg";
import {AddCartToken} from "../../store/token";
import shopApi from "../../api/shop";
import {IState} from "../../types/state";
import {getCartDataMount} from "../../store/cart";
import cls from "./account.module.scss"

const PageHeader = dynamic(() => import('../../components/shared/PageHeader'))

interface IProps {
    children?: ReactNode
}


const links = [
    {
        title: <FormattedMessage id="personal_information" defaultMessage="Personal information"/>,
        url: "profile",
        icon: <Account/>,
        id: 1,
    },
    {
        title: <FormattedMessage id="my_orders" defaultMessage="My orders"/>,
        url: "orders",
        icon: <History className={cls["historyIcon-svg"]}/>,
        id: 2,
    },
    {
        title: <FormattedMessage id="address" defaultMessage="Address"/>,
        url: "addresses",
        icon: <Location/>,
        id: 3,
    },
    {
        title: <FormattedMessage id="sign_out" defaultMessage="Exit"/>,
        url: "logout",
        icon: <LogOut/>,
        id: 4,
    }
]

const AccountLayout: FC<IProps> = (props) => {
    const customer = useSelector((state: IState) => state.customer);
    const locale = useSelector((state: IState) => state.locale.code)
    const { cartToken } = useSelector((state: IState) => state.cartToken);
    const dispatch = useDispatch();
    const {children} = props;
    const history = useRouter();


    const logout = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        dispatch({type: "AUTHENTICATED", payload: false});
        dispatch({type: "CUSTOMER_TOKEN", payload: ""});

        shopApi.getCustomerLogoutToken(customer.token)
            .then(item => {
                if (item.ok) {
                    shopApi.getCheckoutCartToken()
                        .then(res => {
                            if (res.api_token) {
                                dispatch(AddCartToken(res.api_token));

                            }
                        })
                }
            })

        dispatch(getCartDataMount(cartToken, customer, locale));

        // history.push(`https://${dbName}/`);
        //TODO!!! remove unused code
        history.push("/");

    };

    const breadcrumb = [
        {
            title: <FormattedMessage id="home" defaultMessage="Home"/>,
            url: `/`
        },
        {
            title: <FormattedMessage id="my_account" defaultMessage="My account"/>,
            url: `/account/profile`
        }
    ];


    const newLinks = links.map((link) => {

        const classes = classNames(cls["account-nav__item"],
            history.route.includes(link.url) && cls["account-nav__item--active"]
        );

        return (
            <li key={link.id} className={classes}>
                {
                    link.url === "logout"
                        ? (
                            <Link href="/account/logout">
                                <a onClick={logout} className={cls.logout}>
                                    <span>
                                        <LogOut/>
                                    </span>
                                    <FormattedMessage id="sign_out" defaultMessage="Sign out"/>
                                </a>
                            </Link>
                        )
                        : (
                            <Link href={`/account/${link.url}`}>
                                <a>
                                    <span>{link.icon}</span>
                                    {link.title}
                                </a>
                            </Link>
                        )
                }
            </li>
        );
    });

    return (
        <>
            <PageHeader header={breadcrumb[1].title} breadcrumb={breadcrumb}/>
            <h1 className={cls["account-nav__title"]}>
                <FormattedMessage id="my_account" defaultMessage="My Account"/>
            </h1>
            <div className={cls.block}>
                <div className="container">
                    <div className={cls["account-layout-wrapper"]}>
                        <div className={cls["account-layout"]}>
                            <div className={cls["account-nav"]}>
                                <ul className={`${cls.card} ${cls["account-links-body"]}`}>
                                    {newLinks}
                                </ul>
                            </div>
                        </div>
                        <div className={`${cls["account-layout"]} ${cls["account-layout-desktop"]}`}>
                            <div className={cls["account-layout-content-margin"]}>{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountLayout
