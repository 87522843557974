import React, {FC, useState} from "react";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import {domainUrl} from "../../helper";
import Head from "next/head";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import cls from "./header.module.scss"
import {useRouter} from "next/router";
import {PropsConsumer} from "../../context";
import Image from "next/image";
import Script from "next/script";
import {schemaSiteHeader} from "../schemaorg";

const NavLinks = dynamic(() => import("./NavLinks/NavLinks"))
const NavPanel = dynamic(() => import("./NavPanel/NavPanel"))

interface IHeaderProps {
    layout?: string
}

const Header: FC<IHeaderProps> = ({layout = "default"}) => {
    const dispatch = useDispatch();
    const dbName = useSelector((state: IState) => state.general.dbName)
    const domain = useSelector((state: IState) => state.general.domain)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const router = useRouter();

    const [imgSrc, setImgSrc] = useState(`${dbName}/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`)

    const loadDefaultLogo = () => {
        setImgSrc(`${dbName}/stores-logo.svg?v=${cacheVersion}`)
    }

    const schema = schemaSiteHeader(dbName, router.asPath)

    return (
        <>
            <Script
                id={schema.name}
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
            />
            <div className={cls["site-header"]}>
                <div className={cls["site-header__middle"]}>
                    <div className="container">
                        <div className={cls["site-header__nav-panel"]}>
                            <PropsConsumer>
                                {
                                    props => (
                                        <NavPanel layout={layout} {...props}/>
                                    )
                                }
                            </PropsConsumer>
                        </div>
                    </div>
                </div>
                <div className={cls["site-header__bottom"]}>
                    <div className="container">
                        <PropsConsumer>
                            {
                                props => (
                                    <NavLinks pageProps={props} layout={layout}/>
                                )
                            }
                        </PropsConsumer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(Header);