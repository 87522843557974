import React, {FC, memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {detectLocale} from "../../services/utils";
import SSRAccount from "./FooterAccount/index";
import {useRouter} from "next/router";
import {setWindowSize} from "../../store/general";
import {FormattedMessage} from "react-intl";
import Link from "next/link";
import footerCls from "./Footer-scss/Footer.module.scss"

const FooterContacts = dynamic(() => import('./FooterContacts'))
const FooterNewsletter = dynamic(() => import('./FooterNewsletter'))
const ToTop = dynamic(() => import('./ToTop'))
const MobileFooterNavs = dynamic(() => import("../mobile/MobileFooterNavs"))

const FooterAccount = dynamic(() => import('./FooterAccount'))

interface IProps {
    pageProps: any
}

const Footer: FC<IProps> = ({pageProps}) => {
    const dispatch = useDispatch()
    const router = useRouter();
    const siteLocale = useSelector((state: IState) => state.locale.code)
    const windowSize = useSelector((state: IState) => state.general.windowSize)
    const footerContent = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_content_footer_footer_content), siteLocale)?.value
    const footerToggle = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_content_footer_footer_toggle), siteLocale)?.value
    const [isTablet, setIsTablet] = useState(false)

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsTablet(windowSize <= 991)
    }, [windowSize]);

    return (
        <>
            <div className={footerCls["site-footer"]} style={{
                minHeight: 225
            }}>
                <div className={`${footerCls["footer-container"]} container`}>
                    <div className={footerCls["footer-body"]}>
                        <div className={footerCls["footer-first-column"]}>
                            {/*{router.asPath === "/" ? (*/}
                            {/*    <FooterAccount*/}
                            {/*        pageProps={pageProps}*/}
                            {/*    />*/}
                            {/*) : (*/}
                                <SSRAccount pageProps={pageProps} dbName={pageProps.dbName} domain={pageProps?.domain}/>
                            {/*)}*/}
                        </div>

                        <div className={footerCls["footer-newsletter-hide"]}>
                            <FooterContacts pageProps={pageProps}/>
                        </div>

                        <div className={footerCls["newsletter-block"]} style={{display: pageProps.showSubscription}}>
                            <FooterNewsletter/>
                        </div>
                    </div>
                    <div className={footerCls.copyright}>
                        {footerToggle &&
                            <p>{router.asPath === "/" ? pageProps.footerContent?.value : footerContent}</p>
                        }
                        <div style={{display: "flex", gap: "5px", justifyContent: "center"}}>
                            <FormattedMessage
                                id="developed"
                                defaultMessage="Developed by"
                            />
                            <Link href="https://www.zegashop.com">
                                <a style={{
                                    color: "#000",
                                    fontWeight: "600",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                                   target="_blank"
                                >
                                    Zegashop.com
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
                <ToTop/>
            </div>
            {isTablet && <div>
                <MobileFooterNavs/>
            </div>}
        </>

    );
}

export default memo(Footer);
