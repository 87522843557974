import React, {FC, memo, useEffect, useState} from "react";
import Link from "next/link";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {domainUrl} from "../../helper";
import {Cross20Svg, MenuTabletSvg, Mobilemenu, SearchssSvg,} from "../../svg";
import {mobileMenuOpen, mobileSearchClose} from "../../store/mobile-menu";
import dynamic from 'next/dynamic'
import cls from "./mobile.module.scss"
import {IState} from "../../types/state";
import Image from "next/image";
import {setWindowSize} from "../../store/general";
import {Indicator} from "../header/Indicator";

const Search = dynamic(() => import('../header/Search/Search'))
const DropdownLanguage = dynamic(() => import('../header/Dropdown/DropdownLanguage'))

interface IProps {
    dbName?: string;
    domain?: string
}

const MobileHeader: FC<IProps> = ({ dbName, domain }) => {
    const domainCopy = useSelector((state: IState) => state.general.domain)
    const dbNameCopy = useSelector((state: IState) => state.general.dbName)
    const mobileMenu = useSelector((state: IState) => state.mobileMenu.open)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const searchReduxState = useSelector((state: IState) => state.mobileMenu.searchOpen)
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    const [isMobile, setIsMobile] = useState(false);
    const Domain = domain ?? domainCopy;
    const DBName = dbName ?? dbNameCopy
    const dispatch = useDispatch()

    const [searchOpen, setSearchOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsMobile(windowSize <= 600)
    }, [windowSize])

    const handleOpenSearch = () => setSearchOpen(true)
    const handleCloseSearch = () => setSearchOpen(false)

    const [imgSrc, setImgSrc] = useState(`${DBName}/storage/${Domain}/configuration/logo/logo.webp?v=${cacheVersion ?? false}`)
    const loadDefaultLogo = () => {
        setImgSrc(`${DBName}/stores-logo.svg?v=${cacheVersion ?? false}`)
    }


    const searchClasses = classNames(cls["mobile-header__search"], searchReduxState ? cls["mobile-header__search--open"] : "");

    const ClosedOrOpen = !mobileMenu ? <Mobilemenu/> : <Cross20Svg/>

    return (
        <div className={cls["mobile-header"]}>
            <div className={cls["mobile-header__panel"]}>
                <div>
                    <div className={`${cls["mobile-header__body"]} container`}>
                        {
                            isMobile && <button
                                type="button"
                                className={cls["mobile-header__menu-button"]}
                                onClick={() => dispatch(mobileMenuOpen())}
                                aria-label="MobileHeaderBtn"
                            >
                                <Mobilemenu className={cls["mobile-header__menu-button-burger"]}/>
                            </button>
                        }
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className={cls["mobile-header__menu-button"]}*/}
                        {/*    onClick={() => dispatch(mobileMenuOpen())}*/}
                        {/*    aria-label="MobileHeaderBtn"*/}
                        {/*>*/}
                        {/*    {ClosedOrOpen}*/}
                        {/*</button>*/}
                        <div className={cls.header__logo}>
                            <Link href="/">
                                <a aria-label="go to home page">
                                    <Image
                                        src={domainUrl(imgSrc)}
                                        alt="mobile-logo"
                                        placeholder="blur"
                                        layout="fill"
                                        blurDataURL={`/zega/blur1.webp`}
                                        objectFit='contain'
                                        onError={loadDefaultLogo}
                                    />
                                </a>
                            </Link>
                        </div>
                        {
                            !isMobile && <div className={cls["site-header__search"]}>
                                <Search context="header"/>
                            </div>
                        }

                        {
                            !isMobile && <button
                                type="button"
                                className={cls["mobile-header__menu-button"]}
                                onClick={() => dispatch(mobileMenuOpen())}
                                aria-label="MobileHeaderBtn"
                            >
                                <MenuTabletSvg className={cls["mobile-header__menu-button-burger"]}/>
                            </button>
                        }

                        {/*{*/}
                        {/*    isMobile && <div className="mobile-header__indicators">*/}
                        {/*        <Indicator*/}
                        {/*            className="indicator--mobile indicator--mobile-search search-icon d-md-none"*/}
                        {/*            onClick={handleOpenSearch}*/}
                        {/*            icon={*/}
                        {/*                searchOpen ?*/}
                        {/*                    <Cross20Svg className="close_btn"/> :*/}
                        {/*                    <SearchssSvg/>*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {
                            isMobile && <div className={"mobile-header-dropdown"}>
                                <DropdownLanguage/>
                            </div>
                        }
                    </div>
                    {
                        isMobile && <Search
                            context="mobile-header"
                            className={searchClasses}
                            onClose={() => dispatch(mobileSearchClose())}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default memo(MobileHeader);
