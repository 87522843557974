import React, {FC, useEffect, useState} from "react";
import Link from "next/link";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {IMenuList, IState} from "../../../types/state";
import {IFooterAccount as IProps} from ".././FooterNewsLetter/types";
import footerCls from "../Footer-scss/Footer.module.scss"
import Image from "next/image";
import {domainUrl} from "../../../helper";

const SSRAccount: FC<{ dbName?: string; domain?: string; pageProps: any }> = ({dbName, domain, pageProps}) => {
    // TODO!!!! without SSR
    const [footerLinks, setFooterLinks] = useState<Array<IMenuList>>([]);
    const hasTracking = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.sales_tracking_tracking_active?.value)))
    const hasContact = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_contact_us_active?.value)));
    const menuList = useSelector((state: IState) => state.general.menuList)
    const locale = useSelector((state: IState) => state.locale)
    const dbNameCopy = useSelector((state: IState) => state.general.dbName)
    const DBName = dbName ?? dbNameCopy
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const [imgSrc, setImgSrc] = useState(`${DBName}/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion ?? false}`)

    useEffect(() => {
        setFooterLinks(menuList?.filter((item) => item.type === "footer"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale])

    const loadDefaultLogo = () => {
        setImgSrc(`${DBName}/stores-logo.svg?v=${cacheVersion ?? false}`)
    }

    return (
        <>
            <div className={footerCls["footer__logo"]}>
                <Link href="/">
                    <a aria-label="go to home page">
                        <Image
                            src={domainUrl(imgSrc)}
                            alt="mobile-logo"
                            placeholder="blur"
                            layout="fill"
                            blurDataURL={`/zega/blur1.webp`}
                            objectFit='contain'
                            onError={loadDefaultLogo}
                        />
                    </a>
                </Link>
            </div>
            <div className="footer_text">
                <p>
                    <FormattedMessage
                        id="footer.text"
                        defaultMessage="Lorem ipsum dolor sit amet consectetur. Proin nunc proin et sed. "
                    />
                </p>

            </div>

            {
                pageProps?.hasTracking?.map((item: any, index: any) => (
                    <div key={index} className={footerCls["trackingNumber-fms"]}>
                        <Link href={item.href}>
                            <a>
                                <FormattedMessage
                                    id={item.id}
                                    defaultMessage={item.defaultMessage}
                                />
                            </a>
                        </Link>
                    </div>
                ))
            }
        </>
    );
}

export default SSRAccount;