import React, {FC, useEffect, useState} from "react";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import Link from "next/link";
import {BlogSVG, ContactusSVG, MobilMenuCloseSvg, TrackingSVG} from "../../svg";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {changeLocale} from "../../store/locale";
import {mobileMenuClose} from "../../store/mobile-menu";
import {IItem} from "../../types/header";
import cls from "./mobile.module.scss"
import Image from "next/image";
import {domainUrl} from "../../helper";
import {setWindowSize} from "../../store/general";
import {useRouter} from "next/router";

const MobileLinks = dynamic(() => import('./MobileLinks'))
const MobileHeader = dynamic(() => import('./MobileHeader'))
const MobileMenuFooter = dynamic(() => import('../footer/FooterMobile/MobileMenuFooter'))
const MobileLinksForPages = dynamic(() => import('./MobileLinksForPages'))


const MobileMenu: FC<{ dbName?: string, domain?: string }> = ({ dbName, domain }) => {
    const dispatch = useDispatch();

    const domainCopy = useSelector((state: IState) => state.general.domain)
    const dbNameCopy = useSelector((state: IState) => state.general.dbName)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const languages = useSelector((state: IState) => state.locale.list);
    const hasBlog = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_blog_active?.value)))
    const hasTrackingActive = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.sales_tracking_tracking_active?.value)))
    const hasContactWidthUs = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_contact_us_active?.value)));
    const windowSize = useSelector((state: IState) => state.general.windowSize)
    const locale = useSelector((state: IState) => state.locale.code)
    const router = useRouter()
    const mobileMenuState = useSelector((state: IState) => state.mobileMenu);
    const menuPagesList = useSelector((state: IState) => state.mobileMenu.mobileMenuList);
    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const Domain = domain ?? domainCopy;
    const DBName = dbName ?? dbNameCopy

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsTablet(windowSize <= 991)
        setIsMobile(windowSize <= 425)
    }, [windowSize])

    const pages = <FormattedMessage id="pages" defaultMessage="Pages"/>;
    const category = (
        <FormattedMessage id="categoies" defaultMessage="Category"/>
    );

    const classes = classNames(cls["mobilemenu"],
        mobileMenuState.open && cls["mobilemenu--open"]
    );

    const navLinksMobile = useSelector((state: IState) =>
        state.general.menuList?.filter((items: {
            type: string;
        }) => items.type == "header").sort((a, b) => Number(a.position) - Number(b.position))
    );
    const navLinksMobileFooter = useSelector((state: IState) =>
        state.general.menuList?.filter((items) => items.type == "footer").sort((a, b) => Number(a.position) - Number(b.position))
    );

    const together = [...navLinksMobile, ...navLinksMobileFooter]
    const handleItemClick = (item?: { data?: { type: string; locale: string; }; type?: string; }) => {

        if (item?.data) {
            if (item.data.type === "language") {
                dispatch(changeLocale(item.data.locale));
                dispatch(mobileMenuClose());
            }
        }
        dispatch(mobileMenuClose());
    };
    const [imgSrc, setImgSrc] = useState(`${DBName}/storage/${Domain}/configuration/logo/logo.webp?v=${cacheVersion ?? false}`)
    const loadDefaultLogo = () => {
        setImgSrc(`${DBName}/stores-logo.svg?v=${cacheVersion ?? false}`)
    }

    const statecategories = useSelector((state: IState) => state.general.categories);
    const categories = [{label: category, children: statecategories}];

    const handleRoute = (locale: any) => {
        // console.log("kjjhk")
        return router.push(
            router.asPath !== "/" ? router.asPath : "",
            router.asPath !== "/" ? router.asPath : "",
            {locale: locale.code}
        );
    };

    return (
        <div className={classes} style={{opacity: mobileMenuState.open ? 1 : 0}}>
            <div className={cls.mobilemenu__backdrop} onClick={() => dispatch(mobileMenuClose())}/>
            <div className={cls.mobilemenu__body}>
                <div className={cls.menu__header}>
                    <div className={cls.header__logo}>
                        <Link href="/">
                            <a aria-label="go to home page">
                                <Image
                                    src={domainUrl(imgSrc)}
                                    alt="mobile-logo"
                                    placeholder="blur"
                                    layout="fill"
                                    blurDataURL={`/zega/blur1.webp`}
                                    objectFit='contain'
                                    onError={loadDefaultLogo}
                                />
                            </a>
                        </Link>
                    </div>
                    <button
                        type="button"
                        className={cls["mobile-header__menu-button"]}
                        onClick={() => dispatch({type: "MOBILE_MENU_OPEN", payload: false})}
                        aria-label="MobileHeaderBtn"
                    >
                        <MobilMenuCloseSvg/>
                    </button>
                </div>
                <div className={cls["mobilemenu__content"]}>
                    <MobileLinks links={statecategories as IItem[]} onItemClick={handleItemClick}/>
                    {isTablet && menuPagesList ? (
                        <>
                            <MobileLinksForPages
                                links={[...together]}
                                onItemClick={handleItemClick}
                            />

                            {
                                hasBlog ?
                                    <ul className={cls["mob-links__item"]}>
                                        <Link href="/blog">
                                            <a className={cls["mob-links__blok-a"]}
                                               onClick={() => dispatch(mobileMenuClose())}>
                                                <span className={cls["mobile-links__blok"]}>
                                                    {/*<BlogSVG/>*/}
                                                    <FormattedMessage id="blog" defaultMessage="Blog"/>
                                                </span>
                                            </a>
                                        </Link>
                                    </ul>
                                    :
                                    <></>
                            }
                            {hasContactWidthUs && (
                                <div
                                    className={cls["mob-links__item"]}
                                >
                                    <Link href="/contact">
                                        <a
                                            className={cls["mob-links__blok-a"]}
                                            onClick={() => dispatch(mobileMenuClose())}
                                        >
                                            <span className={cls["mobile-links__blok"]}>
                                            {/*<ContactusSVG/>*/}
                                                <FormattedMessage
                                                    id="contact_with_us"
                                                    defaultMessage="Contact With Us"
                                                />
                                            </span>
                                        </a>

                                    </Link>
                                </div>
                            )}
                            {hasTrackingActive && (
                                <div
                                    className={cls["mob-links__item"]}
                                    style={{borderBottom: "none"}}
                                >
                                    <Link href="/tracking">
                                        <a
                                            className={cls["mob-links__blok-a"]}
                                            onClick={() => dispatch(mobileMenuClose())}
                                        >
                                            <span className={cls["mobile-links__blok"]}>
                                                {/*<TrackingSVG/>*/}
                                                <FormattedMessage
                                                    id="tracking"
                                                    defaultMessage="Tracking Number"
                                                />
                                            </span>
                                        </a>
                                    </Link>
                                </div>
                            )}
                            {/*{isMobile && <div className={cls["language_list"]}>*/}
                            {/*    {languages?.map((el, index) => {*/}
                            {/*        const diffProductImage = el && el.locale_image*/}
                            {/*            ? `${dbName}/storage/${domain}/${el.locale_image}`*/}
                            {/*            : `${dbName}/vendor/webkul/ui/assets/images/flag_${locale}.svg`*/}

                            {/*        return <div key={index} className={cls.lang_image} onClick={() => handleRoute(el)}>*/}
                            {/*            <Image*/}
                            {/*                src={domainUrl(diffProductImage)}*/}
                            {/*                alt="language"*/}
                            {/*                height={32}*/}
                            {/*                width={32}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    })}*/}
                            {/*</div>*/}
                            {/*}*/}
                        </>
                    ) : (
                        ""
                    )}
                </div>
                {/*<div className="mobileMenuFooter-fms">*/}
                {/*    <MobileMenuFooter />*/}
                {/*</div>*/}
            </div>
        </div>
    );
}


export default MobileMenu
