import React, {ReactNode, useEffect, useRef, useState} from "react";
import {IProps} from "../types/homePageTypes";
import {PropsConsumer} from "../context";
import {useRouter} from "next/router";
import {setWindowSize} from "../store/general";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../types/state";

import TopBar from "./header/TopBar"
import Footer from "./footer"
import Header from "./header"
import MobileMenu from "./mobile/MobileMenu"
import MobileFooter from "./footer/FooterMobile/MobileFooter"
import MobileHeader from "./mobile/MobileHeader"
import {mobileMenuClose} from "../store/mobile-menu";

const MainContent = ({children}: { children: ReactNode }) => {
    const dispatch = useDispatch()
    const router = useRouter()
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    const [isTablet, setIsTablet] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const headerRef = useRef<any>(null)
    let scrollTimeout: any

    useEffect(() => {
        const {query} = router

        if (query.room_id) {
            sessionStorage.setItem("roomID", query.room_id as string)
        }
    }, [])

    useEffect(() => {

        const windowResizeEvent = () => {
            dispatch(setWindowSize(window.innerWidth))
        }

        window.addEventListener("resize", windowResizeEvent)

        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsTablet(windowSize <= 991)
        setIsMobile(windowSize <= 768)

        return () => {
            window.removeEventListener("resize", windowResizeEvent)
        }
    }, [windowSize]);

    useEffect(() => {
        let prevPos = window.pageYOffset;

        const scrollFunc = () => {
            if (headerRef.current) {
                if (window.pageYOffset > prevPos) {
                    headerRef.current.style.transform = 'translate(0, -98px)';
                    dispatch(mobileMenuClose())
                } else {
                    headerRef.current.style.transform = 'unset';
                }
                prevPos = window.pageYOffset;
            }

            clearTimeout(scrollTimeout);

            scrollTimeout = setTimeout(() => {
                if (headerRef.current) {
                    headerRef.current.style.transform = 'unset';
                }
            }, 300);
        };

        window.addEventListener('scroll', scrollFunc);

        return () => {
            window.removeEventListener('scroll', scrollFunc);
            clearTimeout(scrollTimeout);
        };
    }, []);

    const Headers = isTablet
        ?
        <>
            <TopBar/>
                <PropsConsumer>
                    {
                        props => (
                            <div className="m_headerWrapper" ref={headerRef}>
                                <MobileHeader dbName={props?.dbName} domain={props?.domain}/>
                                <MobileMenu dbName={props?.dbName} domain={props?.domain}/>
                            </div>
                        )
                    }
                </PropsConsumer>
        </>
        :
        <>
            <TopBar/>
            <div className="d_headerWrapper">
                <Header/>
                <PropsConsumer>
                    {
                        props => (
                                <MobileMenu dbName={props?.dbName} domain={props?.domain}/>
                            )
                    }
                </PropsConsumer>
            </div>

        </>

    // const Footers = isTablet
    //     ?
    //     <MobileFooter/>
    //     : (
    //         <PropsConsumer>
    //             {
    //                 props => {
    //                     return <Footer pageProps={props as IProps}/>
    //                 }
    //             }
    //         </PropsConsumer>
    //     )

    const Footers = (
        <PropsConsumer>
            {
                props => {
                    return (<Footer pageProps={props as IProps}/>)
                }
            }
        </PropsConsumer>
    )

    return (
        <>
            {Headers}
            {children}
            {Footers}
        </>
    )
}

export default MainContent;